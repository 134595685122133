import React, { Component } from "react"
import { Link, graphql } from "gatsby"

import Layout from "../layouts"
import arrow from "../images/arrow.png";
import Social from '../components/social';

const img1 = require('../images/android-icon-192x192.png');
const img2 = require('../images/favicon-32x32.png');
const img3 = require('../images/favicon-96x96.png');
const img4 = require('../images/favicon-16x16.png');

class Blog extends Component {
    render() {
        const data = this.props.data
        const { group, index, first, last, pageCount } = this.props.pathContext;
        const previousUrl = (index <= pageCount && index > 1) && (index - 1).toString()
        const nextUrl = ((index + 1) <= pageCount && index >= 1) && (index + 1).toString()

        console.log(group, index, first, last, pageCount)
        const posts = group.map((e,i) => {

            console.log(e)

            let cats = e.node.categories.map((el,ix) => {
                return <li key={ix}>{el.name}</li>
            });

            console.log(e.node.wpml_translations)

            return e.node.wpml_translations[0] ? <article className={"col-5 col-sm-12 posts__post"} key={i}>
                <div className="posts__meta">
                    <time className={"posts__time"}>{new Date(e.node.date).toLocaleDateString()}</time>
                    <ul className="posts__cats">
                        {cats}
                    </ul>
                </div>
                <Link  activeStyle={{color: "#0033cc"}}  className={"posts__link"} to={`/${e.node.slug}`}>
                    <h2 className={"posts__title"} dangerouslySetInnerHTML={{__html: e.node.wpml_translations[0].post_title}}>
                    </h2>
                </Link>

                <div className="posts__excerpt" dangerouslySetInnerHTML={{__html: e.node.wpml_translations[0].excerpt}}></div>
                <Social link={typeof window !== 'undefined' ? `${window.location.origin}/en/${e.node.slug}` : '/'} />
            </article> : <article className={"col-5 posts__post"}>
                <h2 className={"posts__title"}>
                    Not available in English
                </h2>
            </article>;
        });

        if(typeof window === 'undefined') { return null; } let localization = window.location.pathname.split('/');

        let langs = ['pl', 'en', 'de'].map((element, index) => {

            return <li key={index}>
                {
                    element === 'pl' ?
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/teksty`}>{element}</Link> :
                        <Link  activeStyle={{color: "#0033cc"}}  to={`/${element}/teksty`}>{element}</Link>
                }

            </li>
        });

        return (
            <Layout>
                <section className="posts grid">
                    {/* <ul className="langs col-12">
                        {langs}
                    </ul> */}
                    {posts}
                    <ul className="col-12 posts__nav">
                        {
                            previousUrl &&
                            <li className={"posts__prev"}>
                                <Link to={`/en/teksty/${previousUrl == 1 ? '' : previousUrl}`}>
                                    <img src={arrow} alt="prev"/>
                                </Link>
                            </li>

                        }
                        {
                            nextUrl &&
                            <li className={"posts__next"}>
                                <Link to={`/en/teksty/${nextUrl}`}>
                                    <img src={arrow} alt="next"/>
                                </Link>
                            </li>
                        }
                    </ul>
                </section>
            </Layout>
        )
    }
}

export default Blog

// Set here the ID of the home page.
export const pageQuery = graphql`
    query {
        allWordpressPost {
            edges {
                node {
                    date
                    title
                    excerpt
                    slug
                    categories {
                        id
                        name
                        link
                    }
                }
            }
        }
    }
`
